@import "../../styles/Theme.module.scss";

.globalContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.card {
  width: 400px;
  background-color: $translucentDark;
  padding: 20px;
  margin: 0;
}
.languageRow {
  justify-content: flex-end;
}
.titleRow {
  justify-content: space-between;
  text-align: center;
  margin: 20px 20px 30px 20px;
}
.smallTitleRow {
  justify-content: flex-start;
  margin-bottom: 20px;
}
.title {
  margin-bottom: 5px;
}
.text {
  margin-bottom: 10px;
}
.field {
  margin-bottom: 15px;
}
.button {
  margin-top: 25px;
}
.noShow {
  display: none;
}
