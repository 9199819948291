.App {
  text-align: center;
  background-color: rgb(255, 255, 255);
  height: 100vh;
  padding-top: 55px;
}

.AppLogo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .AppLogo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
