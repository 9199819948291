body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url("./styles/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto-Light";
  src: local("Roboto-Light"),
    url("./styles/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url("./styles/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
}
