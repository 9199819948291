.container {
  display: flex;
}

.buttonPrimary {
  border-radius: 4px;
  cursor: pointer;
  flex: 1;
  border: none;
}

.picto {
  cursor: pointer;
  outline: 0;
  align-self: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.link {
  flex: 1;
  cursor: pointer;
}

.pill {
  outline: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
}

.buttonSecondary {
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  cursor: pointer;
  flex: 1;
}
