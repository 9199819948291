$dark: rgba(0, 0, 0, 1);
$transparentDark: rgba(20, 20, 20, 0.3);
$translucentDark: rgba(0, 0, 0, 1);

$light: rgba(255, 255, 255, 1);
$transparentLight: rgba(235, 235, 235, 0.6);
$translucentLight: rgba(255, 255, 255, 0.9);
$transparent: rgba(0, 0, 0, 0);

$disabled: rgba(150, 150, 150, 0.7);

$danger: rgba(200, 0, 0, 1);
$warning: rgba(250, 190, 90, 1);
$valid: rgba(0, 150, 0, 1);

$fakeBlack: rgba(40, 40, 40, 1);

// FONTSIZE

.cursorPointer {
  cursor: pointer;
}

// FONTSIZE

.titleStyle {
  font-family: "Roboto-Regular";
  font-size: 36px;
  line-height: 38px;
}
.smallTitleStyle {
  font-family: "Roboto-Regular";
  font-size: 26px;
  line-height: 28px;
}
.textStyle {
  font-family: "Roboto-Regular";
  font-size: 12px;
  line-height: 14px;
}
.smallTextStyle {
  font-family: "Roboto-Regular";
  font-size: 10px;
  line-height: 13px;
}
.labelStyle {
  font-family: "Roboto-Regular";
  font-size: 12px;
}
.inputStyle {
  font-family: "Roboto-Regular";
  font-size: 16px;
  line-height: 18px;
}
.smallInputStyle {
  font-family: "Roboto-Regular";
  font-size: 12px;
  line-height: 14px;
}

// FONTWEIGHT

.extrabold {
  font-weight: 900;
}
.bold {
  font-family: "Roboto-Bold";
  // font-weight: 900;
}
.normal {
  font-family: "Roboto-Regular";
  // font-weight: 500;
}
.thin {
  font-family: "Roboto-Light";
  // font-weight: 200;
}
.obsolete {
  text-decoration-line: line-through;
}

// COMPONENT STYLE

.card {
  padding: 20px 20px 25px 20px;
  margin: 0px 20px 20px 20px;
  border-radius: 5px;
}
.borderedCard {
  padding: 20px 20px 25px 20px;
  margin: 0px 20px 20px 20px;
  border-radius: 5px;
  border-style: solid;
}
.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

// CURSOR

.cursorPointer {
  cursor: pointer;
}

// TEXTALIGN

.textAlignCenter {
  text-align: center;
}

.textAlignRight {
  text-align: right;
}

// BACKGROUND COLOR

.bgColorValid {
  background-color: $valid;
}
.bgColorDanger {
  background-color: $danger;
}
.bgColorWarning {
  background-color: $warning;
}
.bgColorLight {
  background-color: $light;
}
.bgColorDisabled {
  background-color: $disabled;
}
.bgColorTransparent {
  background-color: $transparent;
}
.bgColorTransparentLight {
  background-color: $transparentLight;
}
.bgColorTranslucentLight {
  background-color: $translucentLight;
}
.bgColorDark {
  background-color: $dark;
}
.bgColorTransparentDark {
  background-color: $transparentDark;
}
.bgColorTranslucentDark {
  background-color: $translucentDark;
}
.bgColorTransparent {
  background-color: $transparent;
}
.bgColorFakeBlack {
  background-color: $fakeBlack;
}

// COLOR

.colorDark {
  color: $dark;
}
.colorTransparentDark {
  color: $transparentDark;
}
.colorTranslucentDark {
  color: $translucentDark;
}
.colorValid {
  color: $valid;
}
.colorDanger {
  color: $danger;
}
.colorWarning {
  color: $warning;
}
.colorLight {
  color: $light;
}
.colorTranslucentLight {
  color: $translucentLight;
}
.colorTransparentLight {
  color: $transparentLight;
}
.colorDisabled {
  color: $disabled;
}

// BORDER COLOR

.border {
  border-style: solid;
}
// BORDER COLOR

.borderColorLight {
  border-color: $light;
}
.borderColorTransparentLight {
  border-color: $transparentLight;
}
.borderColorTransparent {
  border-color: $transparent;
}
.borderColorTransparentDark {
  border-color: $transparentDark;
}
.borderColorDark {
  border-color: $dark;
}
.borderColorValid {
  border-color: $valid;
}
.borderColorWarning {
  border-color: $warning;
}
.borderColorDanger {
  border-color: $danger;
}
.borderColorDisabled {
  border-color: $disabled;
}

// JUSTIFY CONTENT

.justifyContentStart {
  justify-content: start;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentEnd {
  justify-content: end;
}
.justifyContentSpaceBetween {
  justify-content: space-between;
}

// ALIGN ITEMS

.alignItemsStart {
  align-items: flex-start;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsEnd {
  align-items: flex-end;
}

// MARGIN

.padding0 {
  padding: 0px;
}

// PADDING VERTICAL

.paddingVertical1 {
  padding-top: 1px;
  padding-bottom: 1px;
}
.paddingVertical2 {
  padding-top: 2px;
  padding-bottom: 2px;
}
.paddingVertical3 {
  padding-top: 3px;
  padding-bottom: 3px;
}
.paddingVertical4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.paddingVertical5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.paddingVertical6 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.paddingVertical7 {
  padding-top: 7px;
  padding-bottom: 7px;
}
.paddingVertical8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.paddingVertical9 {
  padding-top: 9px;
  padding-bottom: 9px;
}
.paddingVertical10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.paddingVertical15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.paddingVertical20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

// MARGIN

.margin0 {
  margin: 0px;
}

// MARGIN VERTICAL

.marginVertical1 {
  margin-top: 1px;
  margin-bottom: 1px;
}
.marginVertical2 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.marginVertical3 {
  margin-top: 3px;
  margin-bottom: 3px;
}
.marginVertical4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.marginVertical5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.marginVertical6 {
  margin-top: 6px;
  margin-bottom: 6px;
}
.marginVertical7 {
  margin-top: 7px;
  margin-bottom: 7px;
}
.marginVertical8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.marginVertical9 {
  margin-top: 9px;
  margin-bottom: 9px;
}
.marginVertical10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.marginVertical15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.marginVertical20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
